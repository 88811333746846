import * as React from "react";
import { forceCheck } from "react-lazyload";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import { EGDSTab, EGDSTabs, EGDSTabsType } from "@egds/react-core/tabs";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSHeading } from "@egds/react-core/text";
import { classNames } from "@egds/react-core/utils";

import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

import { InsideTabGroupContext } from "components/shared/InsideTabGroupContext/InsideTabGroupContext";
import LinksGridWithDialog from "components/flexComponents/Links/GridView/LinksGridWithDialog";

import { TabGroupTemplateComponent, TabGroupProps } from "./typings";
import TabContents from "../TabContents/TabContents";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { ExtendedTemplateComponent } from "stores/ExperienceTemplateStore/typings";
import { withStores } from "stores";
import { getFmId } from "stores/ExperienceTemplateStore";

export const TabGroup = withStores(
  "flexModuleModelStore",
  "context",
  "analytics"
)(
  observer((props: TabGroupProps) => {
    const { templateComponent, flexModuleModelStore, analytics, blossomComponent } = props;

    if (!templateComponent || !templateComponent.children || !blossomComponent) {
      return null;
    }

    const {
      metadata: { id },
      config,
    } = templateComponent;
    if (!config) {
      return null;
    }

    const { fmTitleId, view } = config;
    const fmId = getFmId(templateComponent);
    const { hideTitle, title, dynamicHeight, boxType } = templateComponent.config;

    const filterTemplateComponentTabs = (components: ExtendedTemplateComponent[]): ExtendedTemplateComponent[] => {
      // Tabs cannot have the same name and must contain at least 1 valid module
      const tabNames = new Set();

      return components.filter((component) => {
        // Verify the items in children are tabs, tab name isn't duplicated, and tab has modules
        if (
          !component.type ||
          (component.type && component.type !== "tab") ||
          !component.metadata.name ||
          tabNames.has(component.metadata.name) ||
          !component.children
        ) {
          return false;
        }

        // Verify the number of valid modules, there's no way to know if the render function of a component
        // will return a null value, so the decision is based on other metadata, as well as verifying the module is supported.
        if (!hasRenderableChildren(component, flexModuleModelStore)) {
          return false;
        }

        tabNames.add(component.metadata.name);

        return true;
      });
    };

    const filteredTabs = filterTemplateComponentTabs(templateComponent.children);

    if (!filteredTabs?.length) {
      return null;
    }

    const handleTabSelect = (currentTabIndex: number): void => {
      if (typeof currentTabIndex !== "number") {
        return;
      }

      const currentTab = filteredTabs?.[currentTabIndex];

      if (!currentTab) {
        return;
      }

      const trackingInfo: FlexTrackingInfo = {
        moduleName: id,
        action: Action.CLICK,
        rfrr: `tab.${currentTabIndex}`,
      };
      sendDelayedTrackEvent(trackingInfo, analytics);
      setSelectedTab(currentTabIndex);
    };

    const defaultTabIndex = 0;
    const [selectedTab, setSelectedTab] = React.useState(defaultTabIndex);

    const { hash } = useLocation();

    React.useEffect(() => {
      if (hash.slice(0, 12) === "#section-tab") {
        const anchorId = `tab-${hash.slice(9)}`;
        const tabAnchor = document.getElementById(anchorId)?.children;
        const tabContent = tabAnchor && tabAnchor[0].textContent;

        if (tabAnchor && tabContent) {
          const filteredTabNames = filteredTabs.map((tab) => {
            return tab.metadata.name;
          });
          if (filteredTabNames.includes(tabContent)) setSelectedTab(filteredTabNames.indexOf(tabContent));
          tabAnchor[0].scrollIntoView();
        }
      }
    }, [hash]);

    React.useEffect(() => {
      // Forces the lazy loaded images check to see if they need to be loaded
      // when the current tab changes
      forceCheck();
    }, [selectedTab]);

    const keyHelper = new ItemKeyHelper("tab");
    const tabGroupClasses = classNames("TabGroup", {
      affinityTabGroup: view === "affinity",
      dynamicHeightTabGroup: dynamicHeight,
    });

    if (view === "row-trigger" || view === "standard-link") {
      const linksCardView = view === "standard-link" && filteredTabs.length === 1 ? "no-card" : "";

      return (
        <div id={id} className={`${tabGroupClasses} ${linksCardView}`} data-fm={fmId} data-fm-title-id={fmTitleId}>
          <EGDSCard>
            <EGDSCardContentSection padded={false}>
              <LinksGridWithDialog
                view={view}
                templateComponents={filteredTabs}
                blossomComponent={blossomComponent}
                id={id}
              />
            </EGDSCardContentSection>
          </EGDSCard>
        </div>
      );
    }

    const uitkCardClasses = classNames({ cardBorder: boxType === "bordered-cards" });

    return (
      <div id={id} className={tabGroupClasses} data-fm={fmId} data-fm-title-id={fmTitleId}>
        <EGDSCard className={uitkCardClasses}>
          <EGDSCardContentSection padded={hideTitle ? false : true}>
            {!hideTitle && (
              <EGDSHeading tag="h2" size={4}>
                {title}
              </EGDSHeading>
            )}

            <div>
              <InsideTabGroupContext.Provider value>
                <EGDSTabs selectedTab={selectedTab} onTabSelect={handleTabSelect} tabsType={EGDSTabsType.NATURAL}>
                  {filteredTabs.map((tab, index: number) => {
                    const templateComponentTab = tab as TabGroupTemplateComponent;
                    return (
                      <EGDSTab
                        name={templateComponentTab.metadata.name || templateComponentTab.type || ""}
                        key={keyHelper.next()}
                        targetURI="#"
                        tabLinkAttr={{
                          id: `tab-${id}-tab-${index + 1}`,
                          "data-section-id": `#section-${id}-tab-${index + 1}`,
                        }}
                      >
                        <TabContents templateComponent={templateComponentTab} blossomComponent={blossomComponent} />
                      </EGDSTab>
                    );
                  })}
                </EGDSTabs>
              </InsideTabGroupContext.Provider>
            </div>
          </EGDSCardContentSection>
        </EGDSCard>
      </div>
    );
  })
);

export default TabGroup;
